import styled from "styled-components";

export const Card = styled('div')`
  background-color: rgb(246, 246, 246);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 8px;
  height: 48px;
`

export const Image = styled('img')`
  height: 14px;
  display: block;

  @media(min-width: 768px){
    height: 16px;
  }
`

export const Text = styled('span')`
  font-size: 13px;
  font-weight: 500;

  @media(min-width: 768px){
    font-size: 15px;
  }
`