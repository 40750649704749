import Pagination from "../../../../components/Pagination";
import Pill from "./components/Pill";
import ChatStatus from "./components/ChatStatus";
import InviteMembers from './components/InviteMembers';
import {
  TR,
  TH,
  TD,
  TableHead,
  TableBody,
  Table,
  EmptyState,
  TotalMembers,
  Empty,
  MenuButton
} from "./styles";

import services from "../../../../services";
import Dropdown from '../../../../components/DropdownBis';
import EditMemberModal from './components/EditMemberModal';
import { showModal } from "../../../../store/actions/modal";
import { useDispatch } from "react-redux";
import moreVerticalIcon from '../../../../assets/icons/more-vertical.svg';
import Button from "../../../../components/Button";
import moment from "moment";
import { dateSpliter } from "../../../../helpers/dates-hanlder";
import { ReactElement, useEffect, useMemo, useState } from "react";
import CopyToClipboad from "../../../../components/CopyToClipboad";
import constants from "../../../../constants";
import Image from "../../../../components/Image";
import { formatPrice } from "../../../../helpers/price-formatter";
import ExpireMemberModal from "./components/CancelMemberModal";

const Label = ({ countryCode, showCountryCode }) => {
  const country = constants?.countries?.find((c) => c.code === countryCode);
  return country ? (
    <>
      <Image src={country?.flag} width="20px" height="15px" alt="" />
      {showCountryCode && country?.code}
    </>
  ) : null;
};

const emptyState = (numrows = 20) => {
  return Array.from({ length: numrows }, (_, i) => (
    <TR key={`empty-row-${i}`}>
      <TD><Empty /></TD>
      <TD><Empty /></TD>
      <TD><Empty /></TD>
      <TD><Empty /></TD>
    </TR>
  ));
};

interface Filter {
  [field: string]: (string | number)[];
}

interface ComponentProps {
  groupId?: number;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  data: {
    country: string;
    email: string;
    last_name: string;
    name: string;
    purchased_groups_count: string;
    external_name: string | null;
    status;
    chat_status: string;
    transactions_count;
    billing_cycles;
    migration_flag: string | null;
    remaining_billing_cycles;
    transactions_sum;
    current_amount;
    current_rejected_reason;
    started_at;
    expire_at;
    type;
    period;
    period_unit;
    currency;
    subscription_id: number | null;
  }[];
  filters: Filter[];
  pagination: {
    currentPage: string;
    from: number;
    lastPage: number;
    perPage: string;
    to: number;
    total: number;
  };
  goToPage: (page: number) => void;
}

const convertPeriodToLabel = (period, period_unit) => {
  const periodLabels = {
    month: { 1: 'mensual', 3: 'trimestral', 6: 'semestral' },
    year: { 1: 'anual' }
  };
  return periodLabels[period_unit]?.[period] || 'N/A';
};

const Component = ({
  groupId,
  states,
  data,
  filters,
  pagination,
  goToPage,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<number | null>(null);

  // Memoized conditions for the <TH> elements
  const tableHeaders = useMemo(() => ({
    showCountry: data?.some(item => item?.country),
    showStatus: data?.some(item => item?.status),
    showStartedAt: data?.some(item => item?.started_at),
    showPurchasedGroupsCount: data?.some(item => item?.purchased_groups_count),
    showPaymentMethodVerified: data?.some(item => item?.migration_flag && item?.migration_flag !== "no_migration"),
    showExternalName: data?.some(item => item?.external_name)
  }), [data]);

  const getTotalMembersText = (number) => `${number} ${number > 1 ? "miembros" : "miembro"}`;

  const paginate = (page) => goToPage?.(page);

  const exportSubscribers = () => {
    if (groupId) {
      services?.group?.exportSubscribers(groupId, filters);
    } else {
      services?.community?.exportSubscribers();
    }
  };

  return (
    <>
      {pagination?.total > 0 && (
        <div style={{ textAlign: "right" }}>
          <TotalMembers>{getTotalMembersText(pagination?.total)}</TotalMembers>

          <Button
            type="button"
            options={{ type: "link", skin: "purple", size: "md" }}
            onClick={exportSubscribers}
            style={{ marginLeft: "10px" }}
          >
            Exportar listado
          </Button>
        </div>
      )}

      {!states?.loading && data?.length <= 0 ? (
        <EmptyState key="empty-state-member-list">
          Aún no tienes suscriptores.
        </EmptyState>
      ) : (
        <Table style={{ height: 50 * data?.length + 50 }}>
          {states?.loading ? emptyState() : (
            <>
              <TableHead>
                <TR>
                  <TH className="sticky-left" style={{ minWidth: 140, backgroundColor: '#fafafa' }}>Nombre</TH>
                  {tableHeaders.showCountry && <TH style={{ minWidth: 50 }}>País</TH>}
                  {tableHeaders.showStatus && <TH style={{ minWidth: 180 }}>Estado</TH>}
                  {tableHeaders.showStartedAt && <TH style={{ minWidth: 100 }}>Fecha de<br />suscripción</TH>}
                  <TH style={{ minWidth: 100 }}>Total recibido</TH>
                  <TH>Info. de<br />contacto</TH>
                  {tableHeaders.showPurchasedGroupsCount && <TH>Grupos<br />comprados</TH>}
                  {tableHeaders.showExternalName && <TH>Plan</TH>}
                  {tableHeaders.showPaymentMethodVerified && <TH>Verifico <br/> Met. Pago</TH>}
                  <TH style={{ minWidth: 80 }}>Cobros<br />recibidos</TH>
                  
                  <TH className="sticky-right" style={{
                    minWidth: 100,
                    background: '#fafafa',
                    justifyContent: 'center'
                  }}>Acciones</TH>
                </TR>
              </TableHead>

              <TableBody>
                {data?.map((item, index) => {
                  const memberAt = dateSpliter(
                    moment.unix(
                      item?.started_at?.length === 13 ? Number(item?.started_at) / 1000 : item?.started_at
                    ).format("YYYY-MM-DDTHH:mm:ss.000Z")
                  );

                  return (
                    <TR key={`member-${item?.name}-${item?.last_name}-${index}`}>
                      <TD className="sticky-left" style={{ fontWeight: 500, minWidth: 140, backgroundColor: '#fafafa', paddingLeft: 6 }}>
                        <span>
                          {item?.name ? item?.name : "-"}<br />
                          {item?.last_name ? item?.last_name : ""}
                        </span>
                      </TD>
                      {tableHeaders.showCountry && (
                        <TD style={{ minWidth: 50 }}>
                          {!!item?.country && <Label countryCode={item?.country} showCountryCode={false} />}
                        </TD>
                      )}
                      {tableHeaders.showStatus && (
                        <TD style={{ minWidth: 180 }}>
                          {!!item?.status && (
                            <Pill subscriptionState={item?.status} toolTipPositin={index >= data.length - 2 ? 'right-bottom' : 'right-top'} />
                          )}
                        </TD>
                      )}
                      {tableHeaders.showStartedAt && (
                        <TD style={{ minWidth: 100 }}>
                          {!!item?.started_at && memberAt?.year !== "Invalid date" && (
                            <>{`${memberAt?.day?.number}-${memberAt?.month?.name?.short}-${memberAt?.year}`}</>
                          )}
                        </TD>
                      )}
                      <TD style={{ minWidth: 100 }}>
                        {(item?.transactions_sum !== undefined) && (
                          <span style={{ fontWeight: "500" }}>
                            ${formatPrice(item?.transactions_sum)} <small>{item?.currency}</small>
                          </span>
                        )}
                      </TD>
                      <TD>
                        {!!item?.email && (
                          <CopyToClipboad
                            copy={item?.email}
                            showChildren
                            successStyle={{
                              position: "absolute",
                              left: "100%",
                              top: "50%",
                              transform: "translate(0%, -50%)",
                            }}
                          >
                            {item?.email}
                          </CopyToClipboad>
                        )}
                      </TD>
                      {tableHeaders.showPurchasedGroupsCount && (
                        <TD>
                          {!!item?.purchased_groups_count && (
                            <>{item?.purchased_groups_count}</>
                          )}
                        </TD>
                      )}
                      <TD>
                        {!!item?.external_name && (
                          <div>
                            <span>{item?.external_name}</span><br />
                            <span style={{ fontSize: "12px" }}>
                              {item?.type === "guest" ? "Externo" : `$${item?.current_amount || 0} /${convertPeriodToLabel(item?.period, item?.period_unit)}`}
                            </span>
                          </div>
                        )}
                      </TD>
                      {tableHeaders.showPaymentMethodVerified && (
                        <TD>
                          {item?.migration_flag === "migrated" ? "Si ✅" : item?.migration_flag === "no_migration" ? "No corresp. ➖": "Aún no ⏳"}
                        </TD>
                      )}
                      {tableHeaders.showExternalName && (
                        <TD style={{ minWidth: 80 }}>
                          {item?.billing_cycles > 0 && item?.remaining_billing_cycles
                            ? `${item?.billing_cycles - item?.remaining_billing_cycles} de ${item?.billing_cycles}`
                            : `${item?.transactions_count || 0}`
                          }
                        </TD>
                      )}
                      
                      <TD
                        className="sticky-right"
                        style={{
                          minWidth: 100,
                          background: '#fafafa',
                          justifyContent: 'center'
                        }}
                      >
                        {(item?.type === "guest" || item?.type === "recurring") && (
                          <Dropdown
                            label=""
                            staticLabel={false}
                            icon={moreVerticalIcon}
                            position={index >= data.length - 2 ? 'left-bottom' : 'left-bottom'}
                            styles={{ wrapper: { border: 0, padding: 0 }, list: { minWidth: 100 }, item: { padding: "0px 8px" } }}
                          >

                            {(item?.status !== "expired" && item?.type === "guest") ? (
                              <MenuButton
                                onClick={() => {
                                  setSelectedSubscriptionId(item?.subscription_id);
                                  dispatch(showModal('edit-member-modal'));
                                }}
                                disabled={item?.status === "expired" || item?.type !== "guest"}
                              >
                                Editar
                              </MenuButton>
                            ) : (
                              <></> // Return null instead of boolean
                            )}


                            <MenuButton
                              onClick={() => {
                                setSelectedSubscriptionId(item?.subscription_id);
                                dispatch(showModal('expire-member-modal'));
                              }}
                              disabled={!(
                                (item?.status === "active" || item?.status === "renewal_retrying") &&
                                (item?.type === "guest" || item?.type === "recurring")
                              )}
                            >
                              Cancelar suscripción
                            </MenuButton>
                          </Dropdown>
                        )}
                      </TD>
                    </TR>
                  );
                })}
              </TableBody>
            </>
          )}
        </Table>
      )}

      <EditMemberModal subscriptionId={selectedSubscriptionId} />
      <ExpireMemberModal subscriptionId={selectedSubscriptionId} />

      {pagination?.total > 0 && (
        <Pagination
          states={states}
          key="community-subscriptions-pagination"
          total={pagination?.total}
          from={pagination?.from}
          to={pagination?.to}
          currentPage={pagination?.currentPage}
          lastPage={pagination?.lastPage}
          onClick={paginate}
        />
      )}
    </>
  );
};

export default Component;
