import File from "../../../../../../../components/FormikForm/File";
import ColorPicker from '../../../../../../../components/FormikForm/ColorPicker'
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { deleteFile } from '../../../../../../../store/actions/post';

const Component = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  groupInformation,
  ...rest
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <ColorPicker
        name="primary_color"
        error={errors["primary_color"]}
        touched={touched["primary_color"]}
        value={values["primary_color"]}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Color primario"
        options={{
          label: "Color primario",
          marginBottom: 40,
        }}
      />

      {/* <ColorPicker
        name="secondary_color"
        error={errors["secondary_color"]}
        touched={touched["secondary_color"]}
        value={values["secondary_color"]}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Color secundario"
        options={{
          label: "Color secundario",
          marginBottom: 40,
        }}
      /> */}

      <File
        name="big_logo"
        error={errors["big_logo"]}
        touched={touched["big_logo"]}
        value={values["big_logo"]}
        rules={{
          maxFiles: 1,
          typeFiles: ["image"],
        }}
        onRemoveItem={(event, file) => {
          if (file?.id) {
            dispatch(deleteFile({ idFile: file?.id }));
          }
        }}
        options={{
          label: "Logo grande",
          explainText:
            "Puedes agregar tu logo para la versión de escritorio de la plataforma. Debe ser un archivo JPG o PNG de hasta 20 MB. El tamaño ideal es de 128px (ancho) x 25px (alto).",
          marginBottom: 40,
          showLoadFileCta: false,
        }}
      />

      <File
        name="small_logo"
        error={errors["small_logo"]}
        touched={touched["small_logo"]}
        value={values["small_logo"]}
        rules={{
          maxFiles: 1,
          typeFiles: ["image"],
        }}
        onRemoveItem={(event, file) => {
          if (file?.id) {
            dispatch(deleteFile({ idFile: file?.id }));
          }
        }}
        options={{
          label: "Logo pequeño",
          explainText:
            "Puedes agregar tu logo para la versión móvil de la plataforma. Debe ser un archivo JPG o PNG de hasta 20 MB. El tamaño ideal es de 40px (ancho) x 40px (alto).",
          marginBottom: 40,
          showLoadFileCta: false,
        }}
      />
    </>
  );
};

const state = ({ groupStore }) => {
  const { data: groupInformation } = groupStore.group;

  return {
    groupInformation,
  };
};

export default connect(state)(Component);
