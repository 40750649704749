import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import Link from "../../../../../components/Link";
import constants from "../../../../../constants";
import { Title, Text } from "./styles";
import { Center } from "../../../../../components/StyledComponents";
import { hideModal } from "../../../../../store/actions/modal";
import { User } from "../../../../../types";

interface ComponentProps {
  userInformation: User;
}

const Component = ({ userInformation }: ComponentProps) => {
  const dispatch = useDispatch();
  const { whatsapp } = constants;
  const [message, setMessage] = useState(whatsapp.message);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (!!userInformation?.expert) {
      setName(userInformation?.name);
      setEmail(userInformation?.email);
      setMessage(whatsapp.admin_subscriptions);
    }
  }, [userInformation]);

  return (
    <Modal
      animation="fadeInSlideUp"
      name="modal-contact-support"
      width="659"
    >
      <Title>¿Deseas administrar tus suscripciones?</Title>

      <Text>
        Estamos trabajando en esta funcionalidad. Mientras tanto podes
        <b> cancelar tus suscripciones</b> con ayuda de nuestro equipo de
        soporte. ¡Contáctanos!
      </Text>

      <Center horizontal="right" vertical="center" gap={8}>
        <Button
          options={{
            type: "outline",
            skin: "purple",
            size: "lg",
          }}
          onClick={() => dispatch(hideModal())}
          style={{ marginRight: "16px" }}
        >
          Cancelar
        </Button>

        <Link
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
            native: false,
          }}
          href={`${constants.whatsapp.url}?phone=${whatsapp.number
            }&text=${message.replace("{name}", name).replace("{email}", email)}`}
          target="_blank"
        >
          Contactar soporte
        </Link>
      </Center>
    </Modal>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;

  return {
    userInformation,
  };
};

export default connect(state)(Component);
