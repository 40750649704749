import Input from "../../../../../../components/FormikForm/Input";
import Submit from "../../../../../../components/FormikForm/Submit";
import Modal from "../../../../../../components/Modal";
import congrats from "./../../../../../../assets/icons/congrats.svg";
import { connect, useDispatch } from "react-redux";
import {
  changeUsername,
  resetChangeUsername,
} from "../../../../../../store/actions/user";
import { Congrats } from "./styles";
import { Formik } from "formik";
import {
  schema,
  initialValues,
} from "../../../../../../constants/forms/change-username";
import { User } from "../../../../../../types";

interface ComponentProps {
  userInformation: User;
  changeUsernameStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

const Component = ({
  userInformation,
  changeUsernameStates,
}: ComponentProps) => {
  const dispatch = useDispatch();

  const onFormSubmit = ({ values, actions }) => {
    dispatch(changeUsername({ ...values, userId: userInformation?.id }));
  };

  return (
    <Modal
      animation="fadeInSlideUp"
      name="change-username-modal"
      title="Ingresa tu nuevo nombre de usuario"
      onClose={() => dispatch(resetChangeUsername())}
    >
      {changeUsernameStates.success ? (
        <Congrats>
          <img
            src={congrats}
            alt="Congratulations"
            width="114px"
            height="114px"
          />
          <p>Nombre de usuario actualizado</p>
        </Congrats>
      ) : (
        <Formik
          initialValues={{
            ...initialValues,
            username: userInformation?.expert?.username,
          }}
          onSubmit={(values, actions) => {
            if (onFormSubmit) onFormSubmit({ values, actions });
          }}
          validateOnChange={true}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            isSubmitting,
            handleBlur,
            handleReset,
            handleSubmit,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={handleSubmit}
                id="change-username-form"
              >
                <Input
                  name="username"
                  touched={touched["username"]}
                  error={errors["username"]}
                  value={values["username"]}
                  type="text"
                  disabled={changeUsernameStates.loading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={`${userInformation?.expert?.username}`}
                  options={{
                    marginBottom: 42,
                  }}
                />

                <Submit
                  isSubmmiting={changeUsernameStates.loading}
                  form="change-username-form"
                  options={{
                    skin: "purple",
                    size: "lg",
                    type: "filled",
                    block: true,
                  }}
                >
                  Cambiar
                </Submit>
              </form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};

const state = ({ userStore }) => {
  const { information, changeUsername } = userStore;
  const { data: userInformation, states } = information;
  const { data: changeUsernameInformation, states: changeUsernameStates } =
    changeUsername;
  return {
    userInformation,
    changeUsernameInformation,
    changeUsernameStates,
  };
};

export default connect(state)(Component);
