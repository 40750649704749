import { useEffect, useState, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import Helmet from "react-helmet";
import Loader from "../../../../components/LogoLoader";
import { Page } from "../../components/styles";
import ConfirmSessionCreation from "../components/ConfirmSessionCreation";
import ConfirmSessionDeletion from "../components/ConfirmSessionDeletion";
import ShareInTelegram from "../components/ShareInTelegram";
import ShareLink from "../components/ShareLink";
import Header from "./components/Header";
import {
  Text,
  Title,
  NoSubscribedMessage,
  GoToAccount,
  NoSubscribedMessageTitle,
  PostContent,
  Avatar,
  Name,
  InLine,
} from "./styles";
import { useMarkdown } from "../../../../hooks/useMarkdown";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { showModal } from "../../../../store/actions/modal";
import { getOneSession } from "../../../../store/actions/session";
import { SessionInDto } from "../../../../types/session.dto";
import { getUrlParamByName } from "../../../../helpers/get-url-param";
import { isOwnSession } from "../../../../helpers/isOwnSession";
import forward from "../../../../helpers/fowardUrl";
import { getVimeoVideoIdFromUrl, getYouTubeUrl, isVimeoUrl, isYoutubeUrl } from "../../../../helpers/you-tube";
import constants from "../../../../constants";
import userAvatar from "../../../../assets/avatar.svg";
import { Group, User } from "../../../../types";
import { getGroupById, getGroupBySlug } from "../../../../store/actions/group";
import {
  DateSpliteDto,
  dateSpliter,
  sessionExpired,
  sessionInProgress,
  isExpired,
  isFutureDate,
} from "../../../../helpers/dates-hanlder";
import { isSubscribed } from "../../../../helpers/isSubscribed";
import { Redirect } from "react-router";
import ConfirmVideoUploaded from "../components/ConfirmVideoUploaded";
import { useHistory, useLocation } from "react-router-dom";
import VimeoVideo from "../../Posts/components/VimeoVideo";
import EnVivo from "./components/EnVivo";
import Grabado from "./components/Grabado";

import { useLayout } from "../../../../providers/LayoutProvider";
import MPVerifyYourPaymentInformationSection from "../../../Group/components/MPVerifyYourPaymentInformationSection";
import MPOldSubscribersDisapprovedPaymentSection from "../../../Group/components/MPOldSubscribersDisapprovedPaymentSection";

interface ComponentProps {
  userInformation: User;
  userInformationStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  userSubscriptions;
  userSubscriptionsStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  session: SessionInDto;
  sessionStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  match;
}

interface LocationState {
  from: string;
}


const Component = ({
  userInformation,
  userInformationStates,
  userSubscriptions,
  userSubscriptionsStates,
  session,
  sessionStates,
  match,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOwner, setIsOwner] = useState(false);
  const location = useLocation()
  const previousPage = (location?.state as LocationState)?.from;

  useEffect(() => {
    if (!!match.params) {
      dispatch(getOneSession({ idSession: match.params.id }));
    }
  }, [match]);

  useEffect(() => {
    if (!!session) {
      dispatch(getGroupBySlug(session?.group?.slug));
    }
  }, [session])

  useEffect(() => {
    setIsOwner(
      !!session &&
      !!userInformation &&
      session?.group?.expert?.id === userInformation?.expert?.id
    );
  }, [session, userInformation, userSubscriptions]);

  const backUrl = useMemo(() => {
    if(!!session && !!isOwner && !!previousPage){
      return previousPage;
    } else {
      return `/group/${session?.group?.slug}/sessions/`;
    }
}, [isOwner, session, previousPage])

  useEffect(() => {
    if (getUrlParamByName("new") === "t" && isOwner) {
      dispatch(showModal("modal-new-session-created"));
      window.history.pushState(null, document.title, history.location.pathname);
    }

    if (getUrlParamByName("video-uploaded") === "t" && isOwner) {
      dispatch(showModal("modal-video-uploaded-session"));
      window.history.pushState(null, document.title, history.location.pathname);
    }
  }, [isOwner]);

  useEffect(() => {
    if (sessionStates.success) {
      setLayout({
        ...layout,
        page: {
          title: !!session?.recorded_session_url ? "Grabación de Encuentro" : "Encuentro en vivo",
          description: "Encuentro",
          showBack: true,
          back: backUrl
          // back: `/dashboard/${session?.group?.group_type === 'free_experience' ? 'community/events' : session?.group?.group_type}/${session?.group?.id}/sessions/`,
        },
        dashboard: {
          tabs: {
            visible: false,
          },
        },
        header: {
          visible: true,
          beforeHeader: <><MPVerifyYourPaymentInformationSection /> <MPOldSubscribersDisapprovedPaymentSection /></>,
          actions: [],
        },
      });
    }
  }, [session, backUrl]);

  if (sessionStates.error) {
    return <Redirect to="/404" />;
  }

  if (
    sessionStates.loading ||
    userInformationStates.loading ||
    userSubscriptionsStates.loading
  ) {
    return <Loader />;
  }

  return (
    <Page>
      <Helmet>
        <title>{`${session?.group?.group_name} - ${session?.title}`}</title>
        <meta name="description" content={session?.title} />
      </Helmet>

      {!!session?.recorded_session_url && !isFutureDate(session?.session_datetime) ? (
        <Grabado />
      ) : (
        <EnVivo />
      )}

      {/* <Grid.Container>
        <Grid.Row>
          <Grid.Col offset={{ md: 1 }} md={10}> */}
      {/* <PostContent>
              <Center horizontal="center" vertical="center">
                <Center horizontal="center" vertical="center">
                  <Avatar
                    src={
                      useExpertInfo
                        ? session?.group?.expert?.profile_pic?.url
                        : session?.speaker_profile_pic?.url || userAvatar
                    }
                    alt={
                      useExpertInfo
                        ? session?.group?.creator_name
                        : session?.speaker_name
                    }
                    width="36px"
                    height="36px"
                  />
                  <Center horizontal="center" vertical="top" column gap={2}>
                    <Name>
                      {useExpertInfo
                        ? session?.group?.creator_name
                        : session?.speaker_name}
                    </Name>
                    <CreatedAt>{createdAt?.date}</CreatedAt>
                  </Center>
                </Center>

                {isOwnSession(session, userInformation?.expert?.id) &&
                  !isMobile && (
                    <Center horizontal="right" vertical="center" gap={16}>
                      <Link
                        options={{
                          type: "link",
                          size: "lg",
                          skin: "purple",
                        }}
                        href={`/dashboard/${session?.group?.group_type === "free_experience"
                          ? "community/events"
                          : session?.group?.group_type || "experience"
                          }/${session?.group?.id}/sessions/${session?.id}`}
                      >
                        Editar encuentro
                      </Link>

                      <Button
                        options={{
                          type: "link",
                          size: "lg",
                          skin: "purple",
                        }}
                        onClick={() => {
                          dispatch(showModal("modal-delete-session"));
                        }}
                      >
                        Eliminar
                      </Button>
                    </Center>
                  )}
              </Center>

              {session.cover_pic?.url && (
                <>
                  <Image
                    onContextMenu={e => e.preventDefault()}
                    src={session.cover_pic?.url}
                  />
                  <div style={{ height: isMobile ? 16 : 24 }} />
                </>
              )}

              <div style={{ height: "8px" }} />

              <Title>{session?.title}</Title>

              {(isUserSubscribed ||
                isOwnSession(session, userInformation?.expert?.id)) && (
                  <>
                    <Text>
                      {!jsonStr && !!textStr && textStr}

                      {!!jsonStr && (
                        <RemirrorRenderer
                          json={jsonStr}
                          typeMap={typeMap}
                          markMap={markMap}
                        />
                      )}

                      {!jsonStr && !textStr && ""}
                    </Text>

                    <div style={{ height: "16px" }} />

                    <Center horizontal="left" vertical="top" column gap={24}>
                      <Center horizontal="left" vertical="top" column>
                        <InLine><h4>Detalles del Encuentro:</h4></InLine>
                        <InLine>Fecha: {date?.date}</InLine>
                        <InLine>
                          Horario:
                          {isInProgress
                            ? " Ahora"
                            : ` ${date?.hours}:${date?.minutes}hs`}
                        </InLine>
                        {session?.duration_in_hours && (
                          <InLine>
                            Duración: {session?.duration_in_hours} horas
                          </InLine>
                        )}
                        {videoIsExpired && !!videoExpirationDate && (
                          <InLine>
                            {`La grabación expiró el ${videoExpirationDate?.date} a las ${videoExpirationDate?.hours}:${videoExpirationDate?.minutes}hs`}
                          </InLine>
                        )}
                      </Center>

                      {!hasJoinUrlSession &&
                        hastRecordedSessionUrl &&
                        !isOwnSession(session, userInformation?.expert?.id) && (
                          <span style={{ color: "#6C5CE7" }}>
                            {useExpertInfo
                              ? session?.group?.creator_name
                              : session?.speaker_name}{" "}
                            Todavía no se ha subido el link para unirse al encuentro. Una vez que
                            se cargue podrás unirte directamente desde aquí.
                          </span>
                        )}

                      {!hastRecordedSessionUrl &&
                        hasJoinUrlSession &&
                        !isOwnSession(session, userInformation?.expert?.id) && (
                          <span style={{ color: "#6C5CE7" }}>
                            {useExpertInfo
                              ? session?.group?.creator_name
                              : session?.speaker_name}{" "}
                            Todavía no se ha subido la grabación. Una vez que lo haya
                            hecho podrás verla desde aquí mismo.
                          </span>
                        )}

                      {!hastRecordedSessionUrl &&
                        !hasJoinUrlSession &&
                        !isOwnSession(session, userInformation?.expert?.id) && (
                          <span style={{ color: "#6C5CE7" }}>
                            {useExpertInfo
                              ? session?.group?.creator_name
                              : session?.speaker_name}{" "}
                            Todavía no ha subido la grabación y el link del
                            encuentro. Una vez que se carguen podrás unirte al encuentro o ver su grabacion desde aquí.
                          </span>
                        )}

                      <Center
                        horizontal="left"
                        vertical="top"
                        gap={8}
                        column={isMobile}
                      >
                        {!hastRecordedSessionUrl && (
                          <Link
                            href={
                              !hasJoinUrlSession &&
                                isOwnSession(session, userInformation?.expert?.id)
                                ? `/dashboard/${session?.group?.group_type}/${session?.group?.id}/sessions/${session?.id}`
                                : session?.join_url
                            }
                            disabled={
                              !isOwnSession(session, userInformation?.expert?.id) &&
                              (!hasJoinUrlSession || sessionLinkIsExpired)
                            }
                            target="_blank"
                            style={{ minWidth: "205px" }}
                            options={{
                              type: hasJoinUrlSession ? "filled" : "outline",
                              size: "lg",
                              skin: "purple",
                              native: false,
                              block: isMobile,
                            }}
                          >
                            {!hasJoinUrlSession &&
                              isOwnSession(session, userInformation?.expert?.id)
                              ? "Agregar para unirse al encuentro"
                              : "Unirme al encuentro"}
                          </Link>
                        )}

                        {(!videoIsExpired || !videoExpirationDate) &&
                          (!hastRecordedSessionUrl ||
                            (hastRecordedSessionUrl &&
                              !isYoutubeUrl(session?.recorded_session_url) && !isVimeoUrl(session?.recorded_session_url))) && (
                            <Center
                              horizontal="center"
                              vertical="top"
                              gap={4}
                              column
                            >
                              <Link
                                href={
                                  !hastRecordedSessionUrl &&
                                    isOwnSession(
                                      session,
                                      userInformation?.expert?.id
                                    )
                                    ? `/dashboard/${session?.group?.group_type}/${session?.group?.id}/sessions/${session?.id}`
                                    : session?.recorded_session_url
                                }
                                disabled={
                                  !isOwnSession(
                                    session,
                                    userInformation?.expert?.id
                                  ) && !hastRecordedSessionUrl
                                }
                                style={{ minWidth: "205px" }}
                                target={"_blank"}
                                options={{
                                  native: false,
                                  type: hastRecordedSessionUrl
                                    ? "filled"
                                    : "outline",
                                  size: "lg",
                                  skin: "purple",
                                  block: isMobile,
                                }}
                              >

                                {!hastRecordedSessionUrl &&
                                  isOwnSession(session, userInformation?.expert?.id)
                                  ? "Subir grabación"
                                  : "Ver grabación del encuentro"}
                              </Link>

                              {!!videoExpirationDate && (
                                <h4>
                                  Grabación del Encuentro (disponible hasta el <br />{" "}
                                  {` ${videoExpirationDate?.date} ${videoExpirationDate?.hours}:${videoExpirationDate?.minutes}hs)`}
                                </h4>
                              )}
                            </Center>
                          )}
                      </Center>

                      {(!videoIsExpired || !videoExpirationDate) &&
                        hastRecordedSessionUrl &&
                        (isYoutubeUrl(session?.recorded_session_url) || isVimeoUrl(session?.recorded_session_url)) && (
                          <Center
                            horizontal="center"
                            vertical="top"
                            gap={4}
                            column
                          >
                            {!!videoExpirationDate ? (
                              <h4>
                                {`Grabación del Encuentro (disponible hasta el ${videoExpirationDate?.date} ${videoExpirationDate?.hours}:${videoExpirationDate?.minutes}hs)`}
                              </h4>
                            ) : (
                              <h4>Grabación del Encuentro:</h4>
                            )}

                            {isYoutubeUrl(session?.recorded_session_url) && (
                              <AspectRatio
                                ratio="16/9"
                                maxWidth={940}
                                key={`attached-file-${slugify(
                                  session?.recorded_session_url
                                )}`}
                              >
                                <iframe
                                  style={{ maxWidth: "100%" }}
                                  src={getYouTubeUrl(session?.recorded_session_url)}
                                  frameBorder="0"
                                  allowFullScreen
                                ></iframe>
                              </AspectRatio>
                            )}

                            {isVimeoUrl(session?.recorded_session_url) && (
                              <VimeoVideo videoId={getVimeoVideoIdFromUrl(session?.recorded_session_url)} isPreview={true} />
                            )}
                          </Center>
                        )}
                    </Center>
                  </>
                )}

              {showSubscriptionModal && (
                <NoSubscribedMessage>
                  <NoSubscribedMessageTitle>
                    El contenido de este encuentro es exclusivo para Suscriptores
                  </NoSubscribedMessageTitle>

                  <Link
                    href={`/group/${session?.group?.slug}`}
                    options={{
                      type: "filled",
                      size: "lg",
                      skin: "purple",
                    }}
                  >
                    Suscríbete
                  </Link>

                  {!isLoggedIn && (
                    <GoToAccount>
                      O si ya estas suscripto, <br />
                      <br />
                      <Button
                        onClick={() => {
                          forward.setUrl(match.url);
                          window.location.href =
                            constants.urls.subscriber.signin.url;
                        }}
                        options={{
                          type: "outline",
                          size: "lg",
                          skin: "purple",
                        }}
                      >
                        Iniciar sesión con mi cuenta
                      </Button>
                    </GoToAccount>
                  )}
                </NoSubscribedMessage>
              )}
            </PostContent> */}
      {/* </Grid.Col>
        </Grid.Row>
      </Grid.Container> */}

      {/*showSubscriptionModal && <PayWall postSlug={session?.group?.slug} />*/}

      {/* <ShareInTelegram /> */}
      <ShareLink />
      <ConfirmSessionCreation />
      <ConfirmSessionDeletion />
      <ConfirmVideoUploaded />

      <div style={{ height: "35px" }} />
    </Page>

    
  );
};

const state = ({ userStore, sessionStore }) => {
  const { data: userInformation, states: userInformationStates } =
    userStore.information;
  const { data: userSubscriptions, states: userSubscriptionsStates } =
    userStore.subscriptions;
  const { data: session, states: sessionStates } = sessionStore.one;

  return {
    userInformation,
    userInformationStates,
    userSubscriptions,
    userSubscriptionsStates,
    session,
    sessionStates,
  };
};

export default connect(state)(Component);
