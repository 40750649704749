import {
  GroupCard,
  GroupCardHeader,
  Information,
  GroupName,
  Date,
  Price,
  GroupCardBody,
  StateText,
  LinksOptions,
  Link,
  HorizontalSeparator,
  ExpertName
} from "./styles";
import { Subscription } from "../../../../../types";
import dateHelpers from "../../../../../helpers/dates-hanlder"
import Pill from "../Pill"
import { GroupCardAvatar } from "../../../../../components/ExpertGroupCard/styles"
import { group } from "console";

interface PropsInterface {
  subscription: Subscription;
  onSelect;
}

// const statusLabel = {
//   "active": "Activa",
//   "cancelled": "Cancelada",
//   "expired": "Expirada",
//   "non_renewing": "Proxima a vencer",
//   "pending_invite": "Pendiente de aceptación"
// }

const getSubscriptionMessage = (subscription) => {
  switch (subscription?.status) {
    case "cancelled":
      return subscription?.cancelled_at
        ? subscription?.cancel_schedule_create_at
          ? `Diste de baja tu suscripción el ${dateHelpers.fromUtcSecondsToDateStr(subscription?.cancelled_at, "DD/MM/YYYY")}`
          : `Tu suscripción se canceló el ${dateHelpers.fromUtcSecondsToDateStr(subscription?.cancelled_at, "DD/MM/YYYY")} porque tu pago fue rechazado.`
        : "Tu suscripción fue cancelada"
    case "non_renewing":
      return `No se realizarán más cargos por esta suscripción. ${subscription?.next_billing_at && `Tienes acceso al contenido hasta el ${dateHelpers.inputDate(subscription?.next_billing_at, "DD/MM/YYYY")}`}`
    case "active":
      return subscription?.next_billing_at ? `Tu próxima fecha de facturación es el ${dateHelpers.inputDate(subscription?.next_billing_at, "DD/MM/YYYY")}.` : ""
    case "renewal_retrying":
      return `Intentamos renovar tu suscripción pero fallo el cobro. Haremos reintentos en los próximos días. Puedes actualizar tu método de pago.`
    case "paid":
      return subscription?.started_at ? `Tu pago fue realizado el ${dateHelpers.fromUtcSecondsToDateStr(subscription?.started_at, "DD/MM/YYYY")}` : "Has realizado el pago correctamente"
    case "expired":
      return `Tu suscripción de invitado expiró ${subscription?.cancelled_at ? `el ${dateHelpers.fromUtcSecondsToDateStr(subscription?.cancelled_at, "DD/MM/YYYY")}` : ""}`
    case "not_paid":
      return `Tu suscripción no pudo iniciarse correctamente por un cobro fallido. Actualiza tu método de pago para reintentarlo y desbloquear el contenido.`
    case "waiting_for_gateway":
      return `Tu cobro está en proceso. En algunas horas se confirmará y si hubo algun problema, te avisaremos. Mientras puedes acceder a todo el contenido!`
    case "retrying":
      return `El cobro de tu suscripción esta reintentandose con el nuevo método de pago. En unas horas, te avisaremos el estado de tu pago. Hasta que podamos confirmarlo tu acceso al contenido esta pausado.`
    default:
      return "";
  }
};

const getGroupTypeText = (type) => {
  switch (type) {
    case "membership":
      return "Membresia";
    case "experience":
      return "Taller"
    case "free_experience":
      return "Taller gratuito"
    default:
      return "Grupo"
  }
}

const secondaryCtaText = {
  "active": {
    "ctaText": "Cancelar suscripción",
    "skinType": "link"
  },
  "renewal_retrying": {
    "ctaText": "Cancelar suscripción",
    "skinType": "link"
  },
  "cancelled": {
    "ctaText": "Re-Suscribirme",
    "skinType": "filled"
  },
  "non_renewing": {
    "ctaText": "Reactivar suscripción",
    "skinType": "filled"
  }
}

const getPlanText = (subscription) => {
  const planType = subscription?.plan?.type;
  const hasPeriod = !!subscription?.plan?.period && !!subscription?.plan?.period_unit;
  const billingCycles = subscription?.plan?.billing_cycles;
  const currentAmount = subscription?.current_amount || 0;
  const currency = subscription?.plan?.currency;

  let subscriptionDetails;

  if (planType === "guest") {
    subscriptionDetails = "Invitado";
  } else if (hasPeriod) {
    const billingCyclesText = billingCycles ? `${billingCycles} cobros x ` : "";
    const periodLabel = convertPeriodToLabel(subscription?.plan?.period, subscription?.plan?.period_unit);
    subscriptionDetails = `${billingCyclesText} ${currency} ${currentAmount} /${periodLabel}`;
  } else {
    subscriptionDetails = `${currency} ${currentAmount} /pago único`;
  }

  return subscriptionDetails;
}

const convertPeriodToLabel = (period, period_unit) => {
  if (period_unit === "month") {
    switch (period) {
      case 1:
        return "mensual";
      case 3:
        return "trimestral";
      case 6:
        return "semestral";
      default:
        return "N/A";
    }
  } else if (period_unit === "year" && period === 1) {
    return "anual";
  } else {
    return "periodo no reconocido";
  }
}

const Component = ({ onSelect, subscription }: PropsInterface) => {
  return (
    <GroupCard>
      <GroupCardHeader>
        <GroupCardAvatar
          src={subscription?.group?.expert?.profile_pic?.url}
          alt="Profile Logo"
          width="50px"
          height="50px"
        />

        <Information>
          <ExpertName> {getGroupTypeText(subscription?.group?.group_type)} de {subscription?.group?.expert?.username}</ExpertName>
          <GroupName>"{subscription?.group?.group_name}"</GroupName>


        </Information>
      </GroupCardHeader>
      <HorizontalSeparator />

      <GroupCardBody>
        <Pill subscriptionState={subscription?.status} />
        <StateText>
          {getSubscriptionMessage(subscription)}
        </StateText>

        <Price>
          <span>Plan:</span> {" "} {getPlanText(subscription)}
        </Price>

        <Date>
          <span>Fecha de inicio: {dateHelpers.fromUtcSecondsToDateStr(subscription?.started_at)}</span>
        </Date>

        <LinksOptions>
          {/* <Link
            href={""}
            options={{
              skin: "purple",
              size: "md",
              type: "outline",
            }}
          >
            Cambiar método de pago
          </Link> */}
          {(subscription?.status === "active" || subscription?.status === "renewal_retrying" || subscription?.status === "not_paid") && subscription?.payment_processor === "mercadopago" && (
            <Link
              href={
                subscription?.status === "not_paid"
                  ? `/checkout/retry-payment?planId=${subscription?.plan?.id}&groupSlug=${subscription?.group?.slug}&subscriptionId=${subscription?.id}`
                  : `/profile/manage-subscriptions/update?subscriptionId=${subscription?.id}`
              }
              target="_blank"
              options={{
                skin: "purple",
                size: "lg",
                type: "filled",
              }}
            >
              {subscription?.status === "active" || subscription?.status === "renewal_retrying" ? "Cambiar método de pago" : "Reintentar pago"}
            </Link>
          )}

          {!!secondaryCtaText[subscription?.status] && (
            <Link
              href='#'
              onClick={() => onSelect(subscription)}
              options={{
                skin: "purple",
                size: "lg",
                type: secondaryCtaText[subscription?.status].skinType,
              }}
            >
              {secondaryCtaText[subscription?.status].ctaText}
            </Link>


          )}

        </LinksOptions>
      </GroupCardBody>
    </GroupCard>
  );
};

export default Component;
