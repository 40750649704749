import { connect, useDispatch } from "react-redux";
import { changeUserPassword, resetChangeUserPassword } from "./../../../../../store/actions/user";
import Snackbar from "../../../../../components/Snackbar";
import { SectionTitle, EditPropertyBtn, Section, ValueText } from "../../styles";
import pencil from "./../../../../../assets/pencil.svg";
import Icon from "./../../../../../components/Icon";
import { useEffect } from "react";

const Component = ({ userPasswordStates, userData }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (userPasswordStates.success || userPasswordStates.error) {
      setTimeout(() => {
        dispatch(resetChangeUserPassword())
      }, 10000)
    }

  }, [userPasswordStates])

  return (
    <>
      <Section>
        <SectionTitle>Contraseña</SectionTitle>

        <ValueText>
          *************

          <EditPropertyBtn
            type="button"
            onClick={() => dispatch(changeUserPassword(userData?.email))}
            disabled={userPasswordStates.loading}
          >
            {userPasswordStates.loading ?
              <Icon icon={pencil} size="16px" color="#6C5CE7" /> :
              <Icon icon={pencil} size="16px" color="#6C5CE7" />
            }
          </EditPropertyBtn>
        </ValueText>
      </Section>

      <Snackbar
        visible={userPasswordStates.success || userPasswordStates.error}
        options={{
          time: 8000,
          type: userPasswordStates.success ? "success" : "error",
        }}
      >
        {/* {"auth/too-many-requests"} */}
        {userPasswordStates.success
          ? "Te enviamos un e-mail. Recuerda revisar SPAM/Correo no deseado."
          : "Error al enviar e-mail."}
      </Snackbar>
    </>
  );
};

const state = ({ userStore }) => {
  const { information, changeUserPassword, subscriptionsManagerUrl } =
    userStore;
  const { data: userData, states: userStates } = information;
  return {
    userData,
    userStates,
    userPasswordStates: changeUserPassword.states,
    subscriptionsManagerUrl,
  };
};

export default connect(state)(Component);
