import { connect, useDispatch } from "react-redux";
import { showModal } from "../../../../../store/actions/modal";
import { SectionTitle, EditPropertyBtn, ValueText, Section } from "../../styles";
import pencil from "./../../../../../assets/pencil.svg";
import Icon from "./../../../../../components/Icon";

const Component = ({ userData }) => {
  const dispatch = useDispatch();
  return (
    <Section>
      <SectionTitle>Teléfono de contacto</SectionTitle>

      <ValueText>
        {userData?.phone_number}
        <EditPropertyBtn
          type="button"
          onClick={() => dispatch(showModal("modal-phone-number"))}
        >
          <Icon icon={pencil} size="16px" color="#6C5CE7" />
        </EditPropertyBtn>
      </ValueText>
    </Section>
  );
};

const state = ({ userStore }) => {
  const { information } = userStore;

  const { data: userData, states: userStates } = information;

  return {
    userData,
    userStates,
  };
};

export default connect(state)(Component);
