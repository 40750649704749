import styled from "styled-components";
import UiButton from "../../../components/Button";

export const Title = styled('h3')`
  margin: 0;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.Black};
  font-weight: 600;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 20px;
    line-height: 24px;
  }
`;


export const Section = styled('div')`
  position: relative;
`;


export const SectionTitle = styled('p')`
  margin: 0px;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.Black};
`;


export const Text = styled('p')`
margin: 0;
font-size: 14px;
color: #2D3436;
`

export const ValueText = styled('p')`
  margin: 0;
  font-size: 16px;
  color: #2D3436;
  font-weight: 500;
  position: relative;
  padding-right: 35px;
  width: fit-content;
`

export const FormTitle = styled.h1`
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 37px;
  color: ${(props) => props.theme.colors.Black};
  font-weight: 600;
  text-align: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 36px;
    line-height: 44px;
    text-align: left;
    margin-bottom: 49px;
  }
`;

export const Button = styled(UiButton)`
  padding: 0 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  position: relative;
  text-decoration: none;
  gap: 8px;

  &:hover {
    text-decoration: none;
  }

  &:after {
    content: '';
    display: block;
    height: 1px;
    background-color: #6C5CE7;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
`;

export const EditPropertyBtn = styled("button")`
  margin: 0px;
  border: 0px;
  border: 1px solid #6C5CE7;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -2px;
  right: 0px;
  border-radius: 100%;
  cursor: pointer;
  z-index: 10;
`;