import { connect, useDispatch } from "react-redux";
import { showModal } from "./../../../../../store/actions/modal";
import Icon from "../../../../../components/Icon";
import pencilIcon from "../../../../../assets/pencil.svg";
import { MaterialPicker } from 'react-color'
import {
  Section, Text, Button
} from "../../styles";

import {
  Image,
  Card,
  Title,
  Color,
  SelectedColorShape
} from './styles'

import logoBig from "../../../../../assets/logo.svg";
import logoSmall from "../../../../../assets/logo-icon.svg";

import EditSidebar from "./components/ChangeWhiteLabelSidebar";
import { useMemo } from "react";

const Component = ({ userData }) => {
  const dispatch = useDispatch();

  const thereIsCustomBranding = useMemo(() => {
    return userData?.expert?.primary_color || userData?.expert?.logoSmall || userData?.expert?.logoBig
  }, [userData])

  const customStyles = {
    default: {
      material: { height: 'auto', width: '100%', maxWidth: 129, display: 'flex', flexDirection: 'column' as 'column', gap: 8, padding: "17px 19px 29px" },
      HEXwrap: { paddingTop: '16px' },
      HEXinput: { fontSize: '13px', fontWeight: 500, fontFamily: 'Inter, sans-serif', height: 'auto', margin: 0, display: 'block', borderWidth: 0 },
      HEXlabel: { fontSize: '12px', fontFamily: 'Inter, sans-serif', lineHeight: '16px' },
      Hex: {},
      RGBwrap: { paddingTop: '16px' },
      RGBinput: { fontSize: '13px', fontWeight: 500, fontFamily: 'Inter, sans-serif', height: 'auto', minWidth: 25, margin: 0, display: 'block', borderWidth: 0 },
      RGBlabel: { fontSize: '12px', fontFamily: 'Inter, sans-serif', lineHeight: '16px' },
      split: { marginRight: 0, padding: 0, gap: 8 },
      third: { padding: 0, },
    }
  }

  return (
    <>
      <Section>
        {!thereIsCustomBranding && (
          <Card>
            <Title>Personaliza tu Espacio</Title>
            <div style={{ height: 12 }} />
            <Text> Elige un logo y color que representen la identidad visual de tu comunidad. ¡Hazla destacar!</Text>
            <div style={{ height: 18 }} />
            <Button
              type="button"
              onClick={() => dispatch(showModal("change-white-label-sidebar"))}
              loading={false}
              options={{
                skin: "purple",
                size: "lg",
                type: "filled",
              }}
            >
              Editar apariencia <Icon icon={pencilIcon} size="19px" color="white" />
            </Button>
          </Card>
        )}

        {!!thereIsCustomBranding && (
          <Card style={{ padding: 32 }}>
            <Text>Color Primario</Text>
            <div style={{ height: 8 }} />

            <Color style={{ maxWidth: 210 }}>
              <MaterialPicker
                color={`rgb(${userData?.expert?.primary_color || '108, 92, 231'})`}
                styles={customStyles}
              />
              <div style={{ width: '100%', height: '120px', backgroundColor: `rgb(${userData?.expert?.primary_color || '108, 92, 231'})` }} />
            </Color>

            <div style={{ height: 28 }} />

            <Text>Logo Grande (para computadora)</Text>
            <div style={{ height: 8 }} />
            <Image style={{maxWidth: 100, maxHeight: 100}} src={userData?.expert?.big_logo?.url || logoBig} />

            <div style={{ height: 28 }} />

            <Text>Logo Chico (para celulares)</Text>
            <div style={{ height: 8 }} />
            <Image style={{maxWidth: 100, maxHeight: 100}} src={userData?.expert?.small_logo?.url || logoSmall} />

            <div style={{ height: 28 }} />

            <Button
              type="button"
              onClick={() => dispatch(showModal("change-white-label-sidebar"))}
              loading={false}
              options={{
                skin: "purple",
                size: "lg",
                type: "link",
              }}
            >
              Modificar apariencia <Icon icon={pencilIcon} size="17px" color="#6C5CE7" />
            </Button>
          </Card>
        )}
      </Section>

      <EditSidebar />
    </>
  );
};

const state = ({ userStore }) => {
  const { information } = userStore;

  const { data: userData, states: userStates } = information;

  return {
    userData,
    userStates,
  };
};

export default connect(state)(Component);
