import { connect } from "react-redux";
import {
  Card,
  Text,
  Image
} from './styles'
import logoBig from "../../../../../assets/logo.svg";
import { useMemo } from "react";
import { useWindowSize } from "../../../../../hooks/useWindowSize";



const Component = ({ group, groupStates, post, user }) => {
  const { isMobile } = useWindowSize()

  const isOwnGroup = useMemo(() => {
    const expertId = group?.expert?.id || post?.group?.expert?.id
    return expertId === user?.expert?.id;
  }, [group, post, user]);

  if (groupStates.loading || !group.expert?.show_by_klouser || isOwnGroup) {
    return <></>
  }

  return (
    <>
      <Card>
        <Text>En colaboración con</Text>
        <a href="/" style={{ display: 'block' }}>
          <Image src={logoBig} alt="Creator Logo" loading="lazy" />
        </a>
      </Card>
      {isMobile && <div style={{ height: 5 }} />}
    </>
  )
};


const state = ({ groupStore, userStore, postStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: user } = userStore.information;
  const { data: post } = postStore.post;

  return {
    group,
    groupStates,
    user,
    post
  };
};

export default connect(state)(Component);
