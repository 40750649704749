import { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Formik, FormikHelpers } from 'formik';
import Sidebar from "../../../../../../../components/Sidebar";
import Button from "../../../../../../../components/Button";
import Submit from "../../../../../../../components/FormikForm/Submit";
import Snackbar from "../../../../../../../components/Snackbar";
import { initialValues, schema } from '../../../../../../../constants/forms/white-label/edit'
import { resetUpdateWhiteLabel, updateWhiteLabel } from '../../../../../../../store/actions/user';
import { hideModal } from '../../../../../../../store/actions/modal';
import FormFields from "../Form"
interface ComponentProps {
  expert
  updateWhiteLabelStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string
  }
  onClose?: () => void
  visibleModal: string
}

const Component = ({
  expert,
  updateWhiteLabelStates,
  onClose,
  visibleModal
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [actions, setActions] = useState<FormikHelpers<any>>();
  const [formSubmmited, setFormSubmmited] = useState(false);

  const onSubmit = ({ values, actions }) => {
    setActions(actions)
    dispatch(updateWhiteLabel(values));
  };

  const handleClose = () => {
    dispatch(hideModal())
    !!onClose && onClose();
  }

  const whiteLabel = useMemo(() => ({
    primary_color: expert?.primary_color,
    big_logo: expert?.big_logo ? [expert?.big_logo] : [],
    small_logo: expert?.small_logo ? [expert?.small_logo] : []
  }), [expert])

  useEffect(() => {
    if (updateWhiteLabelStates.success) {
      handleClose();

      setTimeout(() => {
        actions && actions?.resetForm()
        dispatch(resetUpdateWhiteLabel());
      }, 3500);
    }
  }, [updateWhiteLabelStates]);

  return <>
    <Sidebar
      position="right"
      visible={visibleModal === 'change-white-label-sidebar'}
      closeType="back"
      onClose={handleClose}
      width="463px"
      title="Editar marca blanca"
    >
      <Formik
        initialValues={{
          ...initialValues,
          ...whiteLabel,
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}

        validateOnChange={false}
        validateOnBlur={formSubmmited}
        validationSchema={schema}
        enableReinitialize
      >
        {(props) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                props?.handleSubmit(event);
              }}
              id="change-white-label-form"
            >
              <FormFields {...props} />
            </form>
          );
        }}
      </Formik>

      <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
        <Button
          disabled={updateWhiteLabelStates.loading}
          options={{
            type: "outline",
            size: "lg",
            skin: "purple",
          }}
          onClick={handleClose}
        >
          Cancelar
        </Button>

        <Submit
          isSubmmiting={updateWhiteLabelStates.loading}
          options={{
            type: "filled",
            size: "lg",
            skin: "purple",
          }}
          form="change-white-label-form"
        >
          Actualizar
        </Submit>
      </div>
    </Sidebar>

    <Snackbar
      visible={updateWhiteLabelStates.success || !!updateWhiteLabelStates.error}
      options={{
        time: 2000,
        type: updateWhiteLabelStates.success ? "success" : "error",
      }}
    >
      {updateWhiteLabelStates.success
        ? "Marca blanca actualizada."
        : "Error al actualizar la marca blanca."}
    </Snackbar>
  </>
}

const state = ({ userStore, modalStore }) => {
  const { data: user } = userStore.information
  const { states: updateWhiteLabelStates } = userStore.changeWhiteLabel
  const { visibleModal } = modalStore

  return {
    expert: user?.expert,
    updateWhiteLabelStates,
    visibleModal
  };
};

export default connect(state)(Component);