import styled from "styled-components";
import UiAvatarEditor from "react-avatar-editor";
import Image from "../../Image";
import Button from "../../Button";
import Error from "../../Form/Error";

export const AvatarEditor = styled(UiAvatarEditor)`
  border-radius: 8px;
  margin-bottom: 15px;
`;

export const Avatar = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 140px;
  max-height: 140px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    max-width: 160px;
    max-height: 160px;
  }
`;

export const UserAvatar = styled(Image) <{ hasError }>`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: #f0f0f0;
  ${(props) => (props.hasError ? "border: 1px solid red;" : "")}
`;

export const AvatarCta = styled("button")`
  margin: 0px;
  border: 0px;
  border: 1px solid #6C5CE7;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 4px;
  right: 10px;
  border-radius: 100%;
  cursor: pointer;
  z-index: 10;
`;

export const Zoom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 192px;
  text-transform: uppercase;
  font-size: 12px;
  color: gray;
`;

export const UpdatePicture = styled("label")`
  cursor: pointer;
  border: none;
  display: inline-block;
  border: 2px solid #6c5ce7;
  height: calc(30px + 4px);
  background-color: #6c5ce7;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  border-radius: 17px;
  padding: 0px 17px;
  text-decoration: none;
  letter-spacing: 0.25px;
  font-weight: 700;
  min-width: 150px;
  text-align: center;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 15px 0px;
`;
export const Cancel = styled(Button)`
  margin: 0 5px;
`;
export const SavePicture = styled(Button)`
  margin: 0 5px;
`;

export const Editor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
`;

export const ValidationError = styled.p`
  margin: 0;
  font-size: 12px;
  color: red;
  background: #ffe7e7;
  padding: 0 10px;
  border-radius: 12px;
`;

export const AvatarErrorMessage = styled(Error)`
  text-align: center;
`;
