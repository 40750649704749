import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Transition } from "react-transition-group";
import { Animation } from "../Animation";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../store/actions/modal";

import {
  ModalBackdrop,
  Modal,
  ModalDialog,
  ModalContent,
  ModalHeader,
  ModalBody,
  Title,
  Close,
  ModalAnimation,
} from "./styles";

import closeIcon from "../../assets/icons/close.svg";

export interface ModalProps {
  animation?: "fadeIn" |
  "fadeInSlideDown" |
  "fadeInSlideUp" |
  "fadeInSlideLeft" |
  "fadeInSlideRight" |
  "bounceIn" |
  "bounceInDown" |
  "bounceInLeft" |
  "bounceInRight" |
  "bounceInUp" |
  "bounceUpAndDown" |
  "zoomIn";
  children: any;
  showClose?: boolean;
  title?: string;
  name: string;
  onClose?: () => void;
  visibleModal?: string | null;
  styles?: any;
  width?: string;
}

export const Component = ({
  animation = "fadeIn",
  showClose = true,
  children,
  title,
  name,
  onClose,
  visibleModal,
  styles,
  width,
}: ModalProps) => {
  const modalDOM = document.getElementById("modal") as HTMLElement;
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);

  const handleClose = () => {
    dispatch(hideModal());
    setIsVisible(false);

    if (onClose) {
      onClose();
    }
  };

  const setBodyStyles = () => {
    if (isVisible) {
      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    setIsVisible(name === visibleModal);

    if (name !== visibleModal) {
      if (onClose) {
        onClose();
      }
    }
  }, [name, visibleModal]);

  useEffect(() => {
    setBodyStyles();
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <Transition in={isVisible} timeout={350} mountOnEnter>
      {(state) => (
        <>
          <ModalAnimation
            name={animation}
            className={`animation-${state}`}
            width={width}
            {...(!!styles && styles)}
          >
            <Modal>
              <ModalDialog width={width}>
                <ModalContent style={styles?.modalContent}>
                  <ModalHeader>
                    {showClose && (
                      <Close onClick={() => handleClose()}>
                        <img
                          src={closeIcon}
                          alt="X"
                          width="16px"
                          height="16px"
                        />
                      </Close>
                    )}
                    {!!title && <Title>{title}</Title>}
                  </ModalHeader>
                  <ModalBody>{children}</ModalBody>

                  {/* <ModalFooter></ModalFooter> */}
                </ModalContent>
              </ModalDialog>
            </Modal>
          </ModalAnimation>

          <Animation name="fadeIn" className={`animation-${state}`}>
            <ModalBackdrop onClick={() => handleClose()} />
          </Animation>
        </>
      )}
    </Transition>,
    modalDOM
  );
};

const state = ({ modalStore }) => ({ ...modalStore });

export default connect(state)(Component);
