import styled from "styled-components";

export const Title = styled('h3')`
  margin: 0;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.Black};
  font-weight: 600;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 20px;
    line-height: 24px;
  }
`;


export const Section = styled('div')`
  position: relative;
`;


export const SectionTitle = styled('p')`
  margin: 0px;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.Black};
`;


export const Text = styled('p')`
margin: 0;
font-size: 14px;
color: #2D3436;
`