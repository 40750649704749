import { useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router";
import Loader from "../../../components/LogoLoader";
import { getGroupBySlug } from "../../../store/actions/group";
import {
  getUserSubscriptions,
} from "../../../store/actions/user";
import { Group, User } from "../../../types";
import { isCongreso } from "../../../helpers/isCongreso";
import { Post } from "../../../types/post";
import { getPosts } from "../../../store/actions/post";
import { isSubscribed } from "../../../helpers/isSubscribed";
import { Layout, useLayout } from "../../../providers/LayoutProvider";
import rainbow from "../../../assets/rainbow-background.svg";
import { isOwnGroup } from "../../../helpers/isOwnGroup";
import { getUrlParamByName } from "../../../helpers/get-url-param";
import { setCookie } from "../../../helpers/cookies-handler";
import { isCommunity } from "../../../helpers/isCommunity";
import FreeGroupsPage from "./components/FreeGroupsPage";
import PaymentGroupsPage from "./components/PaymentGroupsPage";

interface ComponentProps {
  match;
  user: User;
  userStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  subscriptions;
  subscriptionsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  group: Group;
  groupStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  posts: Post[];
  postsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  match,
  user,
  userStates,
  subscriptions,
  subscriptionsStates,
  group,
  groupStates,
  posts,
  postsStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const history = useHistory();

  const { setLayout, layout } = useLayout();

  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "rainbow",
      footer: {
        visible: group?.expert.id !== 2551 && group?.expert?.id !== 10195,
      },
      header: {
        visible: true,
        ...(isSubscribed(subscriptions, group?.id) && {
        }),
      },
      toolbar: {
        visible: isOwnGroup({ group, expertId: user?.expert?.id })
      },
      body: {
        visible: true,
        // background: {
        //   image: rainbow,
        // },
      },
    }),
    [layout, subscriptions, group, user]
  );

  const redirect = useMemo(() => {
    if (!!group) {
      return groupStates?.error || isCongreso(group?.id) || isCommunity(group?.group_type) || (!isOwnGroup({ group, expertId: user?.expert?.id }) && group?.status === 'draft')
    }

    return false
  }, [group, user])

  useEffect(() => {
    if (
      !!match?.params && !groupStates?.loading &&
      (!group?.slug || (!!group?.slug && group?.slug !== match?.params?.group)) && !group
    ) {
      dispatch(getGroupBySlug(match?.params?.slug));
    }
  }, [match]);

  useEffect(() => {
    !!user && !subscriptions && dispatch(getUserSubscriptions(
      {
        filters: {
          status_in: "active,non_renewing,paid,pending_invite,renewal_retrying"
        }
      }
    ))
  }, [user]);

  useEffect(() => {
    if (
      group &&
      (!posts ||
        (!!posts &&
          typeof posts[0]?.group === "number" &&
          posts[0]?.group !== group?.id))
    ) {
      dispatch(getPosts({ pagination: { page: 1, per_parge: 6, _sort: 'desc', _sort_by: 'published_at' }, filters: { group_id: group?.id } }));
    }
  }, [group]);

  useEffect(() => {
    setLayout(layoutData);
  }, [subscriptions, group, user]);

  useEffect(() => {
    if (getUrlParamByName("referral_id")) {
      setCookie("referral_id", getUrlParamByName("referral_id"));
      window.history.pushState(null, document.title, history.location.pathname);
    }
  }, []);

  if (
    groupStates.loading ||
    postsStates.loading ||
    subscriptionsStates.loading ||
    userStates.loading
  ) {
    return <Loader />;
  }

  if (redirect) {
    return <Redirect to="/404" />;
  }

  if (!!group && group?.group_type === 'free_experience') {
    return <FreeGroupsPage match={match} />
  }

  if (!!group && group?.group_type !== 'free_experience') {
    return <PaymentGroupsPage match={match} />
  }

  return <></>
};

const state = ({ userStore, groupStore, postStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: posts, states: postsStates } = postStore.listPosts;
  const { data: user, states: userStates } = userStore.information;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;

  return {
    user,
    userStates,
    subscriptions,
    subscriptionsStates,
    group,
    groupStates,
    posts,
    postsStates,
  };
};

export default connect(state)(Component);
