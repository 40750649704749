import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Text, Title, Section } from "./styles";
import Grid from "../../../components/Grid";
import Link from "../../../components/Link";
import NewGatewaySidebar from './components/GatewaySidebar/New'
import { Layout, useLayout } from "../../../providers/LayoutProvider";
import Snackbar from "../../../components/Snackbar";
import { Link as RrdLink } from "react-router-dom";
import { showModal } from "../../../store/actions/modal";
import GatewaysListSection from "./components/GatewaysListSection";
import Tutorial from "./components/Tutorial";

interface ComponentProps {
  setActiveTab?;
  // children;
  expert;
  linkGatewayStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  setActiveTab,
  // children,
  expert,
  linkGatewayStates
}: ComponentProps) => {
  const dispatch = useDispatch()
  const { layout, setLayout } = useLayout();
  const [showNewGatewaySidebar, setShowNewGatewaySidebar] = useState<boolean>(false);


  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "plain",
      footer: {
        visible: false,
      },
      header: {
        visible: true,
        actions: [],
      },
      page: {
        title: "Configuración de cuenta",
        description: "Configurar cuenta",
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      body: {
        visible: true,
      },
    }),
    [layout]
  );

  useEffect(() => {
    setActiveTab("gateways");
    setLayout(layoutData);
  }, []);


  useEffect(() => {
    if (linkGatewayStates.success) {
      setShowNewGatewaySidebar(false);
    }
  }, [linkGatewayStates]);

  return (
    <>
      {/* {children} */}

      <div style={{ height: 20 }} />

      <Grid.Container>
        <Grid.Row>
          <Grid.Col sm={5}>
            <Title>Cobros en $AR</Title>
            <div style={{ height: 27 }} />
            <Text>
              Conecta tu propia pasarela de pago de Mercado Pago para recibir los cobros de tus suscripciones (Únicamente habilitada para AR$ por el momento!)
            </Text>
            <div style={{ height: "20px" }} />

            <GatewaysListSection setShowNewGatewaySidebar={setShowNewGatewaySidebar} />

            <div style={{ height: "20px" }} />
            <Tutorial />
          </Grid.Col>

          <Grid.Col offset={{ sm: 1 }} sm={5}>
            <Title>Cobros en otras monedas</Title>
            <div style={{ height: 27 }} />
            <Text>
              Lee la guía del link y aprende como comenzar a cobrar en Dolares o Euros.
            </Text>
            <div style={{ height: "20px" }} />
            <Section>
              <Link
                target="_blank"
                options={{
                  skin: "purple",
                  size: "lg",
                  type: "link",
                  native: false,
                }}
                href="https://klouser.notion.site/C-mo-recibo-mi-dinero-adee505814ff4f09bf5ec9c3be3794c5?pvs=4"
              >
                ¿Cómo recibo mi dinero generado en Klouser?
              </Link>
            </Section>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <NewGatewaySidebar
        onClose={setShowNewGatewaySidebar}
        isVisible={showNewGatewaySidebar}
      />

      <Snackbar
        visible={linkGatewayStates.success}
        options={{
          position: "center",
          time: 2000,
          type: "success"
        }}
      >
        Pasarela de pago configurada correctamente
      </Snackbar>

      <Snackbar
        visible={!!linkGatewayStates.error}
        options={{
          position: "center",
          time: 2000,
          type: "error",
        }}
      >
        Hubo un error al configurar la pasarela de pago
      </Snackbar>
    </>
  )
}

const state = ({ expertStore }) => {
  const { data: expert, states: linkGatewayStates } = expertStore.linkGateway;

  return {
    expert,
    linkGatewayStates
  };
};

export default connect(state)(Component);
