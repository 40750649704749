import { Group } from "../../../types";
export interface UserSubscription {
  id: number;
  status: string;
  reference_id: string;
  plan: {
    id: number;
    group_id: number;
    external_name: string;
    platform: string;
  };
  next_billing_date: null;
  billing_period: number;
  current_term_start: string;
  current_term_end: string;
  card_updated_for_retrying: boolean;
  expire_at: string;
  started_at: string;
  next_billing_at: string;
}

export const subscriptionSteps = {
  AUTHENTICATION: "AUTHENTICATION",
  PAYMENT: "PAYMENT",
  AUTHORIZED: "AUTHORIZED",
  CHECKOUT: "CHECKOUT",
};

export const subscriptionStates = {
  CANCELLED: "CANCELLED",
  DONE: "DONE",
  INPROGRESS: "INPROGRESS",
};

export const paymentStatus = {
  PAID: "PAID",
  CANCELLED: "CANCELLED",
  WAITING: "WAITING",
};

export const USER_IS_LOGGEDIN = "USER_IS_LOGGED_IN";
export const USER_IS_ALREADY_SUBSCRIBED = "USER_IS_ALREADY_SUBSCRIBED";
export const SAVE_CURRENT_GROUP = "SAVE_CURRENT_GROUP";
export const SAVE_USER_SUBSCRIPTION = "SAVE_USER_SUBSCRIPTION";
export const SAVE_USER_SUBSCRIPTION_SUCCESS = "SAVE_USER_SUBSCRIPTION_SUCCESS";
export const SAVE_USER_SUBSCRIPTION_ERROR = "SAVE_USER_SUBSCRIPTION_ERROR";

export interface UserIsLoggedId {
  type: typeof USER_IS_LOGGEDIN;
  payload: boolean;
}
export interface UserIsAlreadySubscribed {
  type: typeof USER_IS_ALREADY_SUBSCRIBED;
  payload: boolean;
}
export interface SaveCurrentGroup {
  type: typeof SAVE_CURRENT_GROUP;
  payload: Group;
}
export interface SaveUserSubscription {
  type: typeof SAVE_USER_SUBSCRIPTION;
  payload: string;
}
export interface SaveUserSubscriptionSuccess {
  type: typeof SAVE_USER_SUBSCRIPTION_SUCCESS;
  payload: UserSubscription;
}
export interface SaveUserSubscriptionError {
  type: typeof SAVE_USER_SUBSCRIPTION_ERROR;
  payload: null;
}

/**
 * States
 * group: Group to which the user wants to subscribe
 * subscriptionState: State of the subscription
 * paymentStatus: Status of the payment
 * subcriptionStep: In which step of the subscription is the user. It is seted by the subscription flow service
 * isLoggedIn: Flag to know if user is authenticated
 * isAlreadySubscribed: Flag to know if user is already subscribed to the selected group
 */
export interface State {
  group: Group | null;
  isLoggedIn: boolean;
  isAlreadySubscribed: boolean;
  userSubscription: {
    data: UserSubscription | null;
    states: {
      success: boolean;
      loading: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  group: null,
  isLoggedIn: false,
  isAlreadySubscribed: false,
  userSubscription: {
    data: null,
    states: {
      success: false,
      loading: true,
      error: false,
    },
  },
};

export type Actions =
  | UserIsLoggedId
  | UserIsAlreadySubscribed
  | SaveCurrentGroup
  | SaveUserSubscription
  | SaveUserSubscriptionSuccess
  | SaveUserSubscriptionError;
