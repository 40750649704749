import { connect, useDispatch } from "react-redux";
import { showModal } from "./../../../../../store/actions/modal";
import { SectionTitle, EditPropertyBtn, Section, ValueText } from "../../styles";
import ModalChangeUsername from "./ModalChangeUsername";
import pencil from "./../../../../../assets/pencil.svg";
import Icon from "./../../../../../components/Icon";

const Component = ({ changeUsernameStates, userInformation }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Section>
        <SectionTitle>Nombre de usuario</SectionTitle>
        <ValueText>
          {userInformation?.expert?.username}

          <EditPropertyBtn
            type="button"
            onClick={() => dispatch(showModal("change-username-modal"))}
          >
            <Icon icon={pencil} size="16px" color="#6C5CE7" />
          </EditPropertyBtn>
        </ValueText>
      </Section>

      <ModalChangeUsername />
    </>
  );
};

const state = ({ userStore }) => {
  const { information, changeUsername } = userStore;
  const { data: userInformation } = information;
  return {
    userInformation,
    changeUsernameStates: changeUsername.states,
  };
};

export default connect(state)(Component);
