import styled from 'styled-components'

export const Image = styled('img')`
  max-width: 100%;
`

export const Card = styled('div')`
  background-color: white;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid rgba(105, 119, 155, .25);
`

export const Title = styled('h2')`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
`

export const SelectedColorShape = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
  // border-radius: 100%;
  border: 1px solid #000;
  color: white;
`;

export const Color = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-radius: 8px;
  overflow:hidden;
  box-shadow:  0px 1px 14px 0px rgba(0, 0, 0, 0.07);
  pointer-events: none;

  & > * {
    border-radius: 0;

    & > * {
      box-shadow: none!important;
    }
  }
`