import { GetSubscriptionsOutDto } from "../../types";
import { CHANGE_TRACKING, RESET_CHANGE_TRACKING } from "../types/user/change-tracking";
import { CHANGE_USER_AVATAR, RESET_CHANGE_USER_AVATAR } from "../types/user/change-user-avatar";
import { CHANGE_USER_EMAIL, RESET_CHANGE_USER_EMAIL } from "../types/user/change-user-email";
import { RESET_CHANGE_USER_PASSWORD, CHANGE_USER_PASSWORD } from "../types/user/change-user-password";
import { CHANGE_USER_PHONE, RESET_CHANGE_USER_PHONE } from "../types/user/change-user-phone";
import { CHANGE_USERNAME, RESET_CHANGE_USERNAME } from "../types/user/change-username";
import { CREATE_NEW_EXPERT } from "../types/user/create-new-expert";
import { GET_SUBSCRIPTIONS_MANAGER_URL } from "../types/user/get-subscription-manager-url";
import { GetUserGroups, GET_USER_GROUPS } from "../types/user/get-user-groups";
import { GetUserInformation, GET_USER_INFORMATION, RESET_USER_INFORMATION } from "../types/user/get-user-information";
import { GetUserFreeSubscriptions, GetUserSubscriptions, GET_USER_FREE_SUBSCRIPTIONS, GET_USER_SUBSCRIPTIONS } from "../types/user/get-user-subscriptions";
import { UPDATE_EXPERT_INFORMATION } from "../types/user/update-expert-information";
import { RESET_UPDATE_WHITE_LABEL, UPDATE_WHITE_LABEL } from "../types/user/white-label";

export const getUserInformation = (): GetUserInformation => {
  return { type: GET_USER_INFORMATION, payload: null };
};

export const getUserSubscriptions = (payload?: GetSubscriptionsOutDto): GetUserSubscriptions => {
  return { type: GET_USER_SUBSCRIPTIONS, payload: payload };
};

export const getUserFreeSubscriptions = (): GetUserFreeSubscriptions => {
  return { type: GET_USER_FREE_SUBSCRIPTIONS, payload: null };
};


export const getUserGroups = (payload): GetUserGroups => {
  return { type: GET_USER_GROUPS, payload };
};

export const getSubscriptionsManagerUrl = () => {
  return { type: GET_SUBSCRIPTIONS_MANAGER_URL, payload: null };
};

export const changeUserPhone = (payload: {
  phone_area_code;
  phone_number;
  userId: number;
}) => {
  return { type: CHANGE_USER_PHONE, payload };
};

export const resetchangeUserPhone = () => {
  return { type: RESET_CHANGE_USER_PHONE, payload: null };
};

export const changeUserEmail = (data: { email: string; userId: number }) => {
  return { type: CHANGE_USER_EMAIL, payload: data };
};

export const resetChangeUserEmail = () => {
  return { type: RESET_CHANGE_USER_EMAIL, payload: null };
};

export const changeUsername = (username: string) => {
  return { type: CHANGE_USERNAME, payload: username };
};

export const resetChangeUsername = () => {
  return { type: RESET_CHANGE_USERNAME, payload: null };
};

export const changeUserAvatar = (payload) => {
  return { type: CHANGE_USER_AVATAR, payload };
};

export const resetChangeUserAvatar = () => {
  return { type: RESET_CHANGE_USER_AVATAR, payload: null };
};

export const changeTracking = (payload) => {
  return { type: CHANGE_TRACKING, payload };
};

export const resetChangeTracking = () => {
  return { type: RESET_CHANGE_TRACKING, payload: null };
};

export const changeUserPassword = (email: string) => {
  return { type: CHANGE_USER_PASSWORD, payload: email };
};

export const resetChangeUserPassword = () => {
  return { type: RESET_CHANGE_USER_PASSWORD, payload: null };
};


export const resetUserStore = () => {
  return { type: RESET_USER_INFORMATION, payload: null };
};

export const createNewExpert = (payload) => {
  return { type: CREATE_NEW_EXPERT, payload };
};

export const updateExpertInformation = (payload) => {
  return { type: UPDATE_EXPERT_INFORMATION, payload };
};

export const updateWhiteLabel = (payload) => {
  return { type: UPDATE_WHITE_LABEL, payload };
};

export const resetUpdateWhiteLabel = () => {
  return { type: RESET_UPDATE_WHITE_LABEL, payload: null };
};